import React from "react";
import theme from "theme";
import { Theme, Text, Link, List, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"terms"} />
		<Helmet>
			<title>
				Wavedropper LTD - Empowering Your Digital Presence with Precision
			</title>
			<meta name={"description"} content={"We craft bespoke web solutions that flow naturally with your business growth."} />
			<meta property={"og:title"} content={"Wavedropper LTD - Empowering Your Digital Presence with Precision"} />
			<meta property={"og:description"} content={"We craft bespoke web solutions that flow naturally with your business growth."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/anim-optimized.gif?v=2024-07-04T11:36:37.486Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.TopNavBar />
		<Section>
			<Override slot="SectionContent" margin="100px auto 0px auto" />
			{"  "}
			<Text as="h1" font="--headline1">
				Terms and Conditions
			</Text>
			<Link href="#services" color="#000000">
				Link to Services
			</Link>
			{"  "}
			<Text font="--base">
				Welcome to Wavedropper!
			</Text>
			{"  "}
			<Text font="--base">
				These terms and conditions outline the rules and regulations for the use of Wavedropper LTD's Website, located at wavedropper.com, and the provision of our services.
			</Text>
			{"  \n  "}
			<Text as="h2" font="--headline2">
				1. Application and Entire Agreement
			</Text>
			{"  "}
			<Text font="--base">
				These Terms and Conditions apply to the provision of the services detailed in our quotation (Services) by Wavedropper LTD, a company registered in England and Wales under number 15491549, whose registered office is at 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ (we or us or Service Provider) to the person buying the services (you or Customer).
			</Text>
			{"  "}
			<Text font="--base">
				You are deemed to have accepted these Terms and Conditions when you accept our quotation or from the date of any performance of the Services (whichever happens earlier). These Terms and Conditions and our quotation (the Contract) are the entire agreement between us.
			</Text>
			{"  "}
			<Text font="--base">
				You acknowledge that you have not relied on any statement, promise, or representation made or given by or on our behalf. These Conditions apply to the Contract to the exclusion of any other terms that you try to impose or incorporate, or which are implied by trade, custom, practice, or course of dealing.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				2. Interpretation
			</Text>
			{"  "}
			<List font="--base">
				<Text font="--base">
					A "business day" means any day other than a Saturday, Sunday, or bank holiday in England and Wales.
				</Text>
				<Text font="--base">
					The headings in these Terms and Conditions are for convenience only and do not affect their interpretation.
				</Text>
				<Text font="--base">
					Words imparting the singular number shall include the plural and vice-versa.
				</Text>
			</List>
			{"  "}
			<Text as="h2" font="--headline2">
				3. Cookies
			</Text>
			{"  "}
			<Text font="--base">
				We employ the use of cookies. By accessing Wavedropper, you agreed to use cookies in agreement with Wavedropper LTD's Privacy Policy.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				4. License
			</Text>
			{"  "}
			<Text font="--base">
				Unless otherwise stated, Wavedropper LTD and/or its licensors own the intellectual property rights for all material on Wavedropper. All intellectual property rights are reserved. You may access this from Wavedropper for your own personal use subject to restrictions set in these terms and conditions.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				5. Hyperlinking to our Content
			</Text>
			{"  "}
			<Text font="--base">
				Certain organizations may link to our Website without prior written approval, provided the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				6. Content Liability
			</Text>
			{"  "}
			<Text font="--base">
				We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims arising from your Website.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				7. Reservation of Rights
			</Text>
			{"  "}
			<Text font="--base">
				We reserve the right to request that you remove all links or any particular link to our Website. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				8. Removal of Links from Our Website
			</Text>
			{"  "}
			<Text font="--base">
				If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but are not obligated to or so or to respond to you directly.
			</Text>
			{"  "}
			<Text font="--base">
				We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				9. Disclaimer
			</Text>
			{"  "}
			<Text font="--base">
				To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
			</Text>
			{"  "}
			<List font="--base">
				<Text>
					limit or exclude our or your liability for death or personal injury;
				</Text>
				<Text>
					limit or exclude our or your liability for fraud or fraudulent misrepresentation;
				</Text>
				<Text>
					limit any of our or your liabilities in any way that is not permitted under applicable law; or
				</Text>
				<Text>
					exclude any of our or your liabilities that may not be excluded under applicable law.
				</Text>
			</List>
			{"  "}
			<Text font="--base">
				The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty.
			</Text>
			{"  "}
			<Text font="--base">
				As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2" id="services">
				10. Services
			</Text>
			{"  "}
			<Text font="--base">
				We warrant that we will use reasonable care and skill in our performance of the Services, which will comply with the quotation, including any specification in all material respects. We can make any changes to the Services necessary to comply with any applicable law or safety requirement, and we will notify you if this is necessary.
			</Text>
			{"  "}
			<Text font="--base">
				We will use our reasonable endeavours to complete the performance of the Services within the time agreed or as set out in the quotation; however, time shall not be of the essence in the performance of our obligations.
			</Text>
			{"  "}
			<Text font="--base">
				All of these Terms and Conditions apply to the supply of any goods as well as Services unless we specify otherwise.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				11. Your Obligations
			</Text>
			{"  "}
			<Text font="--base">
				You must obtain any permissions, consents, licences, or otherwise that we need and must provide us with access to any relevant information, materials, properties, and other matters which we need to provide the Services. Failure to comply may result in termination of the Services.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				12. Fees and Deposit
			</Text>
			{"  "}
			<Text font="--base">
				The fees (Fees) for the Services are set out in the quotation and are on a time and materials basis. In addition to the Fees, we can recover from you reasonable incidental expenses, the cost of services provided by third parties required for the performance of the Services, and the cost of any materials required.
			</Text>
			{"  "}
			<Text font="--base">
				You must pay a deposit (Deposit) as detailed in the quotation at the time of accepting the quotation. The Deposit is non-refundable unless we fail to provide the Services and are at fault for such failure (where the failure is not our fault, no refund will be made).
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				13. Cancellation and Amendment
			</Text>
			{"  "}
			<Text font="--base">
				We can withdraw, cancel, or amend a quotation if it has not been accepted by you, or if the Services have not started, within a period of 14 days from the date of the quotation (unless the quotation has been withdrawn).
			</Text>
			{"  "}
			<Text font="--base">
				Either we or you can cancel an order for any reason prior to your acceptance (or rejection) of the quotation.
			</Text>
			{"  "}
			<Text font="--base">
				If you want to amend any details of the Services, you must tell us in writing as soon as possible. We will use reasonable endeavours to make any required changes and additional costs will be included in the Fees and invoiced to you.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				14. Payment
			</Text>
			{"  "}
			<Text font="--base">
				We will invoice you for payment of the Fees either:
			</Text>
			{"  "}
			<List font="--base">
				<Text font="--base">
					when we have completed the Services; or
				</Text>
				<Text font="--base">
					on the invoice dates set out in the quotation.
				</Text>
				<Text font="--base">
					For subscription services, you agree to recurring monthly payments, which will be automatically charged to your selected payment method on the agreed date each month until you cancel the subscription.{"\n\n"}
				</Text>
			</List>
			{"  "}
			<Text font="--base">
				You must pay the Fees due within 30 days of the date of our invoice. Late payments will incur interest at the rate of 4% per annum above the base lending rate of the Bank of England.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				15. Sub-contracting and Assignment
			</Text>
			{"  "}
			<Text font="--base">
				We can assign, transfer, charge, subcontract, or deal in any other manner with all or any of our rights under these Terms and Conditions and can subcontract or delegate in any manner any or all of our obligations to any third party.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				16. Termination
			</Text>
			{"  "}
			<Text font="--base">
				We can terminate the provision of the Services immediately if you commit a material breach of your obligations under these Terms and Conditions, fail to make any amount due under the Contract on the due date for payment, or become insolvent.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				17. Intellectual Property
			</Text>
			{"  "}
			<Text font="--base">
				We reserve all copyright and any other intellectual property rights which may subsist in any goods supplied in connection with the provision of the Services.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				18. Liability and Indemnity
			</Text>
			{"  "}
			<Text font="--base">
				Our liability under these Terms and Conditions is limited to the total amount of Fees payable by you under the Contract. We are not liable for any indirect, special, or consequential loss, damage, costs, or expenses, any loss of profits, business, data, reputation or goodwill, or business interruption.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				19. Data Protection
			</Text>
			{"  "}
			<Text font="--base">
				We comply with all applicable data protection laws in England and Wales. Further information is available in our Data Protection Policy.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				20. Circumstances Beyond a Party's Control
			</Text>
			{"  "}
			<Text font="--base">
				Neither of us is liable for any failure or delay in performing our obligations where such failure or delay results from any cause beyond our reasonable control.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				21. Communications
			</Text>
			{"  "}
			<Text font="--base">
				All notices under these Terms and Conditions must be in writing and signed by, or on behalf of, the party giving notice.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				22. No waiver
			</Text>
			{"  "}
			<Text font="--base">
				No delay, act, or omission by a party in exercising any right or remedy will be deemed a waiver of that, or any other, right or remedy.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				23. Severance
			</Text>
			{"  "}
			<Text font="--base">
				If one or more of these Terms and Conditions is found to be unlawful, invalid, or otherwise unenforceable, that provision will be deemed severed from the remainder of these Terms and Conditions, which will remain valid and enforceable.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				24. Law and Jurisdiction
			</Text>
			{"  "}
			<Text font="--base">
				These Terms and Conditions shall be governed by and interpreted according to the law of England and Wales. All disputes arising under these Terms and Conditions shall be subject to the exclusive jurisdiction of the English and Welsh courts.
			</Text>
			{"  "}
			<Text font="--base">
				By using our website and services, you agree to these terms and conditions. If you do not agree, please do not use our website or services.
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65e4858a867c86001f1cafaf"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});